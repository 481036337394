import React, { useEffect, useState } from 'react';
import { CheckCircle, Mail, X } from 'lucide-react';

const ActionModal = ({ 
  title, 
  message, 
  icon: Icon = CheckCircle,
  iconColor = 'text-green-500',
  primaryButtonText = 'Ja',
  secondaryButtonText = 'Nee', 
  onPrimaryAction,
  onSecondaryAction,
  onClose
}) => {
  const [showModal, setShowModal] = useState(false);
  
  useEffect(() => {
    // Add small delay to allow animation to render
    setTimeout(() => setShowModal(true), 50);
    
    // Prevent body scrolling when modal is open
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  
  const handlePrimaryClick = () => {
    setShowModal(false);
    setTimeout(() => onPrimaryAction(), 300);
  };
  
  const handleSecondaryClick = () => {
    setShowModal(false);
    setTimeout(() => onSecondaryAction(), 300);
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop with animation */}
      <div 
        className={`absolute inset-0 transition-opacity duration-300 ease-in-out ${showModal ? 'opacity-100' : 'opacity-0'}`}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onClick={handleSecondaryClick}
      />
      
      {/* Modal content */}
      <div 
        className={`relative bg-white rounded-lg shadow-2xl w-full max-w-md transition-all duration-300 ease-in-out ${
          showModal ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform -translate-y-4'
        }`}
        style={{ backgroundColor: 'var(--color-bg-primary)' }}
      >
        <div className="flex justify-between items-center p-4 border-b"
             style={{ borderColor: 'var(--color-border)' }}>
          <h3 className="text-lg font-semibold" 
              style={{ color: 'var(--color-text-primary)' }}>
            {title}
          </h3>
          <button 
            onClick={handleSecondaryClick}
            className="text-gray-400 hover:text-gray-600 transition-colors duration-200 p-1 rounded-full hover:bg-gray-100"
            style={{ 
              color: 'var(--color-text-secondary)',
              backgroundColor: 'transparent'
            }}
          >
            <X size={20} />
          </button>
        </div>
        
        <div className="p-8 text-center">
          <div className="relative inline-block mb-4">
            <Icon className={`mx-auto ${iconColor} animate-pulse`} size={64} />
          </div>
          
          <p className="text-gray-600 mb-8 text-lg" style={{ color: 'var(--color-text-secondary)' }}>
            {message}
          </p>
          
          <div className="flex space-x-4 justify-center">
            <button 
              onClick={handleSecondaryClick}
              className="flex-1 px-6 py-3 rounded-md border transition-all duration-200 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              style={{ 
                borderColor: 'var(--color-border)',
                color: 'var(--color-text-primary)',
                backgroundColor: 'var(--color-bg-secondary)',
                focusRingColor: 'var(--color-accent)'
              }}
            >
              {secondaryButtonText}
            </button>
            
            <button 
              onClick={handlePrimaryClick}
              className="flex-1 px-6 py-3 rounded-md transition-all duration-200 hover:shadow-md hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2"
              style={{ 
                backgroundColor: 'var(--color-accent)', 
                color: 'white',
                focusRingColor: 'var(--color-accent)'
              }}
            >
              {primaryButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionModal; 