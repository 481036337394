// src/pages/Register.js
import React, { useState, useEffect, memo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../services/authService';
import { useTheme } from '../context/ThemeContext';
import ThemeToggle from '../components/ThemeToggle';
import { CheckCircle, Mail, ArrowRight } from 'lucide-react';

// Extracted and memoized InputField component to prevent re-renders
const InputField = memo(({ id, name, type, label, icon, placeholder, value, onChange, error }) => (
  <div>
    <label 
      htmlFor={id} 
      className="block text-sm font-medium mb-2"
      style={{ color: 'var(--color-text-secondary)' }}
    >
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" style={{ color: 'var(--color-text-secondary)' }}>
        {icon}
      </div>
      <input
        id={id}
        name={name}
        type={type}
        required
        className={`block w-full pl-10 pr-3 py-3 rounded-lg focus:outline-none focus:ring-2 transition-colors duration-200 ${error ? 'border-red-500 ring-1 ring-red-500' : ''}`}
        style={{ 
          backgroundColor: 'var(--color-input-bg)',
          borderColor: error ? '#ef4444' : 'var(--color-input-border)',
          color: 'var(--color-text-primary)',
          border: error ? '1px solid #ef4444' : '1px solid var(--color-input-border)'
        }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
    {error && (
      <p className="mt-1 text-sm text-red-500">
        {error}
      </p>
    )}
  </div>
));

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    company: '',
    // Other fields will be filled later in profile completion
    profileCompleted: false
  });
  
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
    company: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState('');
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    // Trigger animation after component mounts
    const timer = setTimeout(() => {
      setAnimationComplete(true);
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
      email: '',
      password: '',
      company: '',
    };

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = 'Naam is verplicht';
      isValid = false;
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = 'Email is verplicht';
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Voer een geldig email adres in';
      isValid = false;
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = 'Wachtwoord is verplicht';
      isValid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = 'Wachtwoord moet minimaal 8 karakters bevatten';
      isValid = false;
    }

    // Company validation
    if (!formData.company.trim()) {
      newErrors.company = 'Bedrijfsnaam is verplicht';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Use function form of setState to avoid stale state issues
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    
    // Clear specific field error when user types - also use function form
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Validate form before submitting
    if (!validateForm()) {
      return;
    }
    
    setIsLoading(true);
    
    try {
      console.log('Starting registration process...');
      console.log('Form data:', {
        ...formData,
        password: formData.password ? '[PRESENT]' : '[MISSING]' 
      });
      
      const result = await register(formData);
      console.log('Registration successful:', result);
      
      // Store registered email to display in success message
      setRegisteredEmail(formData.email);
      
      // Show success message
      setRegistrationSuccess(true);
      setIsLoading(false);
      
    } catch (err) {
      console.error('Registration failed with error:', err);
      
      // Handle different error formats
      let errorMessage = 'Er is een fout opgetreden tijdens het registreren.';
      
      if (typeof err === 'string') {
        errorMessage = err;
      } else if (err.message) {
        errorMessage = err.message;
      } else if (err.error) {
        errorMessage = err.error;
      }
      
      // Special handling for specific errors
      if (errorMessage.includes('Email is already registered')) {
        errorMessage = 'Dit email adres is al geregistreerd. Probeer in te loggen of gebruik een ander email adres.';
        setErrors({
          ...errors,
          email: 'Dit email adres is al geregistreerd'
        });
      } else if (errorMessage.includes('Password must be at least 8 characters')) {
        setErrors({
          ...errors,
          password: 'Wachtwoord moet minimaal 8 karakters bevatten'
        });
      } else if (errorMessage.includes('Invalid email format')) {
        setErrors({
          ...errors,
          email: 'Ongeldig email formaat'
        });
      }
      
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  // If registration is successful, show a success message with verification instructions
  if (registrationSuccess) {
    return (
      <div className="min-h-screen flex items-center justify-center" style={{ backgroundColor: 'var(--color-bg-secondary)' }}>
        <div className="max-w-md w-full px-6 py-12 rounded-lg shadow-lg" style={{ backgroundColor: 'var(--color-bg-primary)' }}>
          <div className="flex flex-col items-center text-center">
            <div className="w-16 h-16 rounded-full flex items-center justify-center mb-6" style={{ backgroundColor: 'rgba(34, 197, 94, 0.1)' }}>
              <CheckCircle className="w-10 h-10 text-green-500" />
            </div>
            
            <h2 className="text-2xl font-bold mb-2" style={{ color: 'var(--color-text-primary)' }}>
              Registratie Succesvol!
            </h2>
            
            <p className="mb-6" style={{ color: 'var(--color-text-secondary)' }}>
              Uw account is aangemaakt, maar u moet uw e-mailadres nog verifiëren.
            </p>
            
            <div className="w-full p-6 mb-6 rounded-lg" style={{ backgroundColor: 'var(--color-bg-secondary)' }}>
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 rounded-full flex items-center justify-center mr-4" style={{ backgroundColor: 'var(--color-accent)', color: 'white' }}>
                  <Mail className="w-5 h-5" />
                </div>
                <div className="text-left">
                  <h3 className="font-semibold" style={{ color: 'var(--color-text-primary)' }}>Verificatie e-mail verzonden</h3>
                  <p className="text-sm" style={{ color: 'var(--color-text-secondary)' }}>
                    naar <span className="font-medium">{registeredEmail}</span>
                  </p>
                </div>
              </div>
              
              <ul className="space-y-3 text-left text-sm pl-4 mb-4" style={{ color: 'var(--color-text-secondary)' }}>
                <li className="flex items-start">
                  <span className="inline-block w-4 h-4 rounded-full text-xs text-center font-bold mr-2 mt-0.5" style={{ backgroundColor: 'var(--color-accent)', color: 'white' }}>1</span>
                  Open uw inbox voor {registeredEmail}
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-4 h-4 rounded-full text-xs text-center font-bold mr-2 mt-0.5" style={{ backgroundColor: 'var(--color-accent)', color: 'white' }}>2</span>
                  Open de e-mail met onderwerp "Bevestig uw e-mailadres voor uw Inkooporders-app account"
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-4 h-4 rounded-full text-xs text-center font-bold mr-2 mt-0.5" style={{ backgroundColor: 'var(--color-accent)', color: 'white' }}>3</span>
                  Klik op de verificatielink in de e-mail
                </li>
              </ul>
              
              <p className="text-xs" style={{ color: 'var(--color-text-tertiary)' }}>
                <strong>Tip:</strong> Controleer ook uw spam/ongewenste e-mail map als u de e-mail niet kunt vinden.
              </p>
            </div>
            
            <button
              onClick={handleLoginRedirect}
              className="flex items-center justify-center px-6 py-3 rounded-md text-white w-full transition-all duration-200"
              style={{ backgroundColor: 'var(--color-accent)' }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#1a4ba8';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'var(--color-accent)';
              }}
            >
              Ga naar inloggen
              <ArrowRight className="ml-2 w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex overflow-hidden relative" style={{ backgroundColor: 'var(--color-bg-secondary)' }}>
      {/* Theme toggle in top-right corner */}
      <div className="absolute top-4 right-4 z-10">
        <ThemeToggle />
      </div>
      
      {/* Left side branding panel */}
      <div 
        className="hidden xl:flex xl:flex-col xl:w-1/3 relative overflow-hidden"
        style={{ 
          backgroundColor: 'var(--color-accent)',
          transition: 'all 0.5s ease-in-out'
        }}
      >
        <div className="absolute inset-0" style={{ 
          background: `radial-gradient(circle at 30% 40%, ${theme === 'dark' ? 'rgba(30, 64, 175, 0.8)' : 'rgba(0, 0, 0, 0.1)'} 0%, transparent 70%)`,
          zIndex: 1 
        }} />
        
        <div className="flex flex-col items-center justify-center h-full px-12 z-10 relative">
          <div 
            className={`transform transition-all duration-700 ${animationComplete ? 'translate-y-0 opacity-100 animate-fadeIn' : 'translate-y-8 opacity-0'}`}
            style={{ transitionDelay: '200ms' }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-white mb-6">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
          </div>
          
          <h1 
            className={`text-4xl font-bold text-white mb-4 transform transition-all duration-700 ${animationComplete ? 'translate-y-0 opacity-100 animate-slideDown' : 'translate-y-8 opacity-0'}`}
            style={{ transitionDelay: '300ms' }}
          >
            Inkooporders App
          </h1>
          
          <p 
            className={`text-white/80 text-lg text-center max-w-md transform transition-all duration-700 ${animationComplete ? 'translate-y-0 opacity-100 animate-fadeIn' : 'translate-y-8 opacity-0'}`}
            style={{ transitionDelay: '400ms' }}
          >
            Start vandaag nog met het professioneel beheren van uw inkooporders en facturen.
          </p>
          
          {/* Decorative elements */}
          <div className="absolute bottom-12 left-12 w-24 h-24 rounded-full bg-white/10"></div>
          <div className="absolute top-40 right-20 w-40 h-40 rounded-full bg-white/5"></div>
          <div className="absolute bottom-1/3 right-1/4 w-16 h-16 rounded-full bg-white/10"></div>
        </div>
      </div>
      
      {/* Right side form */}
      <div className="w-full xl:w-2/3 flex items-center justify-center">
        <div 
          className={`max-w-md w-full px-6 py-12 transform transition-all duration-700 ${animationComplete ? 'translate-y-0 opacity-100 animate-slideUp' : 'translate-y-8 opacity-0'}`}
        >
          <div className="text-center mb-8">
            <h2 
              className="text-3xl font-bold mb-2" 
              style={{ color: 'var(--color-text-primary)' }}
            >
              Account aanmaken
            </h2>
            <p 
              className="text-sm" 
              style={{ color: 'var(--color-text-secondary)' }}
            >
              Vul de basisgegevens in om te beginnen
            </p>
          </div>
          
          {error && (
            <div 
              className="mb-6 rounded-lg px-4 py-3 flex items-center border-l-4 border-red-500 transition-all duration-300"
              style={{ 
                backgroundColor: theme === 'dark' ? 'rgba(220, 38, 38, 0.2)' : 'rgba(254, 226, 226, 1)',
                color: theme === 'dark' ? '#f87171' : '#dc2626'
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              <span>{error}</span>
            </div>
          )}
          
          <form onSubmit={handleSubmit} noValidate className="space-y-6">
            <InputField
              id="email"
              name="email"
              type="email"
              label="Email adres"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              }
              placeholder="uw@email.nl"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
            />
            
            <InputField
              id="password"
              name="password"
              type="password"
              label="Wachtwoord"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                </svg>
              }
              placeholder="••••••••"
              value={formData.password}
              onChange={handleChange}
              error={errors.password}
            />
            {!errors.password && (
              <p style={{ color: 'var(--color-text-tertiary)' }} className="mt-1 text-xs">
                Wachtwoord moet minimaal 8 karakters bevatten
              </p>
            )}
            
            <InputField
              id="company"
              name="company"
              type="text"
              label="Bedrijfsnaam"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
                </svg>
              }
              placeholder="Uw bedrijfsnaam"
              value={formData.company}
              onChange={handleChange}
              error={errors.company}
            />
            
            <InputField
              id="name"
              name="name"
              type="text"
              label="Uw naam"
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
              }
              placeholder="Uw volledige naam"
              value={formData.name}
              onChange={handleChange}
              error={errors.name}
            />

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full rounded-lg py-3 font-medium relative overflow-hidden transition-all duration-200 group"
                style={{ 
                  backgroundColor: 'var(--color-accent)',
                  color: 'white',
                  opacity: isLoading ? 0.7 : 1 
                }}
                onMouseOver={(e) => {
                  if (!isLoading) e.currentTarget.style.backgroundColor = '#1a4ba8';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = 'var(--color-accent)';
                }}
              >
                <span className="inline-flex items-center">
                  {isLoading ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2"></div>
                      <span>Registreren...</span>
                    </>
                  ) : (
                    <>
                      Account aanmaken
                    </>
                  )}
                </span>
              </button>
            </div>
            
            <div className="mt-6 text-center" style={{ color: 'var(--color-text-secondary)' }}>
              <p className="text-xs mb-4">
                Door te registreren gaat u akkoord met onze <a href="#" className="hover:underline" style={{ color: 'var(--color-accent)' }}>Algemene voorwaarden</a> en <a href="#" className="hover:underline" style={{ color: 'var(--color-accent)' }}>Privacybeleid</a>.
              </p>
              <p 
                className="text-sm"
                style={{ color: 'var(--color-text-secondary)' }}
              >
                Heeft u al een account?{' '}
                <Link to="/login" className="font-semibold hover:underline transition-colors duration-200" style={{ color: 'var(--color-accent)' }}>
                  Log hier in
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;