// src/services/authService.js
import api from './api';
import { logError } from './errorService';

// Register a new user
export const register = async (userData) => {
  try {
    console.log('Registration attempt with data:', {
      ...userData,
      password: userData.password ? '[HIDDEN]' : undefined
    });
    
    // Use the api service instead of direct axios call to ensure CSRF handling is consistent
    const response = await api.post('/auth/register', userData);
    
    console.log('Registration response:', response.data);
    
    if (response.data) {
      // Store user info in localStorage (without token)
      const userInfo = {
        _id: response.data._id,
        name: response.data.name,
        email: response.data.email,
        company: response.data.company,
        isEmailVerified: response.data.isEmailVerified,
        isLoggedIn: true
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      
      // Store user name and company for email templates
      if (response.data.name) {
        localStorage.setItem('userName', response.data.name);
      }
      if (response.data.company) {
        localStorage.setItem('userCompany', response.data.company);
      }
    }
    
    return response.data;
  } catch (error) {
    console.error('Registration error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      headers: error.response?.headers
    });
    
    logError(error, {
      context: 'authService.register',
      userData: {
        ...userData,
        password: userData.password ? '[HIDDEN]' : undefined
      }
    });
    
    // Special handling for CSRF errors
    if (error.response?.status === 403 && error.response?.data?.error?.includes('CSRF')) {
      console.error('CSRF error detected during registration');
      throw new Error('Er was een beveiligingsprobleem. Vernieuw de pagina en probeer opnieuw.');
    }
    
    // Check for specific error types
    if (error.message.includes('CORS')) {
      throw new Error('Verbindingsprobleem met de server. Controleer uw internetverbinding.');
    }
    
    if (error.message.includes('rate limit')) {
      throw new Error('Te veel aanvragen. Probeer het over enkele minuten opnieuw.');
    }
    
    // Email already exists error
    if (error.response?.status === 400 && error.response?.data?.message?.includes('Email is already registered')) {
      throw new Error('Dit email adres is al geregistreerd. Probeer in te loggen of gebruik een ander email adres.');
    }
    
    throw new Error(error.response?.data?.message || error.message || 'Registration failed');
  }
};

// Login user
export const login = async (email, password) => {
  try {
    // Check if this email was recently verified
    const recentVerification = JSON.parse(localStorage.getItem('recentVerification') || 'null');
    const verifiedTokens = JSON.parse(localStorage.getItem('verifiedTokens') || '{}');
    
    // If there's a recent verification and it matches this email, we might need to help the backend
    const isRecentlyVerified = recentVerification && 
                              recentVerification.email === email &&
                              (recentVerification.status === 'success' || recentVerification.status === 'already-verified') &&
                              // Within last 5 minutes
                              (new Date() - new Date(recentVerification.timestamp)) < 5 * 60 * 1000;
    
    console.log('Login attempt with recently verified email:', isRecentlyVerified);
    
    // Attempt login
    const response = await api.post('/auth/login', { email, password });
    
    if (response.data) {
      // Store only non-sensitive user info in localStorage
      // No tokens or security credentials
      const userInfo = {
        _id: response.data._id,
        name: response.data.name,
        email: response.data.email,
        company: response.data.company,
        isLoggedIn: true
      };
      
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      
      // Store user name and company for email templates
      // These are not security sensitive
      if (response.data.name) {
        localStorage.setItem('userName', response.data.name);
      }
      if (response.data.company) {
        localStorage.setItem('userCompany', response.data.company);
      }
    }
    
    return response.data;
  } catch (error) {
    // Check if this is an email verification error
    if (error.response?.status === 403 && error.response?.data?.emailVerificationRequired) {
      // Check if we have evidence this email was recently verified
      const recentVerification = JSON.parse(localStorage.getItem('recentVerification') || 'null');
      const isRecentlyVerified = recentVerification && 
                                recentVerification.email === email &&
                                (recentVerification.status === 'success' || recentVerification.status === 'already-verified') &&
                                // Within last 5 minutes
                                (new Date() - new Date(recentVerification.timestamp)) < 5 * 60 * 1000;
      
      if (isRecentlyVerified) {
        // The backend might not have updated its cache yet, so we'll try one more time 
        // with a clear message
        console.warn('Backend reports email not verified, but we have evidence of recent verification');
        throw new Error(
          'Uw e-mail is recent geverifieerd maar nog niet verwerkt. ' +
          'Probeer over enkele seconden nogmaals in te loggen of vernieuw de pagina.'
        );
      }
      
      const verificationError = new Error(error.response.data.message || 'Email verification required');
      verificationError.isVerificationError = true;
      verificationError.userId = error.response.data.userId;
      verificationError.email = error.response.data.email;
      throw verificationError;
    }
    // Check if this is a 2FA request
    if (error.response?.status === 200 && error.response?.data?.requiresTwoFactor) {
      const twoFactorError = new Error(error.response.data.message || 'Two-factor authentication required');
      twoFactorError.isTwoFactorRequired = true;
      twoFactorError.userId = error.response.data.userId;
      twoFactorError.email = error.response.data.email;
      throw twoFactorError;
    }
    throw new Error(error.response?.data?.message || error.message || 'Login failed');
  }
};

// Logout user
export const logout = async () => {
  try {
    // Call the logout endpoint to clear the cookie
    await api.get('/auth/logout');
    
    // Clear localStorage data
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userName');
    localStorage.removeItem('userCompany');
  } catch (error) {
    console.error('Logout error', error);
    // Still clear localStorage even if the API call fails
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userName');
    localStorage.removeItem('userCompany');
  }
};

// Get current user from localStorage
export const getCurrentUser = () => {
  const user = localStorage.getItem('userInfo');
  return user ? JSON.parse(user) : null;
};

// Check if user is authenticated
export const isAuthenticated = () => {
  return !!getCurrentUser();
};

// Get user profile
export const getUserProfile = async () => {
  try {
    const response = await api.get('/auth/profile');
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to get user profile');
  }
};

// Update user profile
export const updateUserProfile = async (userData) => {
  try {
    const response = await api.put('/auth/profile', userData);
    
    // Update the user data in localStorage
    const currentUser = getCurrentUser();
    if (currentUser) {
      // Merge the current user data with the updated data
      const updatedUser = { ...currentUser, ...response.data };
      // Save back to localStorage
      localStorage.setItem('userInfo', JSON.stringify(updatedUser));
      
      // Update name and company for email templates
      if (userData.name) {
        localStorage.setItem('userName', userData.name);
      }
      if (userData.company) {
        localStorage.setItem('userCompany', userData.company);
      }
    }
    
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to update user profile');
  }
};

// Get order number settings
export const getOrderNumberSettings = async () => {
  try {
    const response = await api.get('/auth/order-settings');
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to get order number settings');
  }
};

// Update order number settings
export const updateOrderNumberSettings = async (settings) => {
  try {
    const response = await api.put('/auth/order-settings', settings);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to update order number settings');
  }
};

// Get PDF settings
export const getPDFSettings = async () => {
  try {
    const response = await api.get('/auth/pdf-settings');
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to get PDF settings');
  }
};

// Update PDF settings
export const updatePDFSettings = async (settings) => {
  try {
    const response = await api.put('/auth/pdf-settings', settings);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to update PDF settings');
  }
};

// Resend verification email
export const resendVerificationEmail = async () => {
  try {
    const response = await api.post('/auth/resend-verification');
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to resend verification email');
  }
};

// Send verification email to unverified user (without being logged in)
export const sendVerificationToUnverified = async (email) => {
  try {
    const response = await api.post('/auth/resend-verification-public', { email });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to send verification email');
  }
};

// Get next order number (a new function to get the preview of what the next order number will be)
export const getNextOrderNumber = async () => {
  try {
    const response = await api.get('/auth/next-order-number');
    return response.data;
  } catch (error) {
    // If endpoint doesn't exist, fall back to order settings
    console.warn('Next order number endpoint not available', error);
    const settings = await getOrderNumberSettings();
    return { nextNumber: null, settings };
  }
};