// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { login, sendVerificationToUnverified } from '../services/authService';
import { useTheme } from '../context/ThemeContext';
import ThemeToggle from '../components/ThemeToggle';
import FormInput from '../components/FormInput';
import { loginValidation } from '../utils/validationSchemas';
import { logError } from '../services/errorService';
import { CheckCircle } from 'lucide-react';

const Login = () => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [resendingVerification, setResendingVerification] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();
  
  // React Hook Form setup
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: ''
    }
  });

  useEffect(() => {
    // Trigger animation after component mounts
    const timer = setTimeout(() => {
      setAnimationComplete(true);
    }, 300);
    
    // Check for verification status in location state (from VerifyEmail redirect)
    if (location.state?.verificationStatus) {
      const { verificationStatus, verificationMessage, email } = location.state;
      
      if (verificationStatus === 'already-verified' || verificationStatus === 'success') {
        setVerificationSuccess(true);
        setVerificationMessage(verificationMessage || 'Uw e-mail is geverifieerd. U kunt nu inloggen.');
        
        // Auto-fill the email field if available
        if (email) {
          setVerificationEmail(email);
          setValue('email', email);
        }
        
        // Clear any existing error message since verification was successful
        setError('');
        setVerificationRequired(false);
      }
    }
    
    // Check for verification parameter in URL
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('verification') === 'required') {
      setVerificationRequired(true);
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      if (user && user.email) {
        setVerificationEmail(user.email);
      }
      setError('Uw e-mail is nog niet geverifieerd. Controleer uw inbox voor de verificatie e-mail of vraag een nieuwe aan.');
    }
    
    return () => clearTimeout(timer);
  }, [location, setValue]);

  const handleChange = (e) => {
    // This function is no longer used with React Hook Form
  };

  const onSubmit = async (data) => {
    setError('');
    // Don't reset verification status if it was just set from a redirect
    if (!location.state?.verificationStatus) {
      setVerificationRequired(false);
      setVerificationSuccess(false);
    }
    setIsLoading(true);

    try {
      await login(data.email, data.password);
      navigate('/');
    } catch (err) {
      logError(err, { context: 'login' });
      
      // Handle verification error
      if (err.isVerificationError) {
        setVerificationRequired(true);
        setVerificationEmail(err.email || data.email);
        setError('Uw e-mail is nog niet geverifieerd. Controleer uw inbox voor de verificatie e-mail of vraag een nieuwe aan.');
      } else {
        setError(err.message || 'Login failed. Please check your credentials.');
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleResendVerification = async () => {
    if (!verificationEmail) return;
    
    setResendingVerification(true);
    setVerificationSuccess(false);
    
    try {
      await sendVerificationToUnverified(verificationEmail);
      setVerificationSent(true);
      setError('');
    } catch (err) {
      logError(err, { context: 'resendVerification' });
      setError(err.message || 'Failed to send verification email. Please try again.');
    } finally {
      setResendingVerification(false);
    }
  };

  return (
    <div className="min-h-screen flex overflow-hidden" style={{ backgroundColor: 'var(--color-bg-secondary)' }}>
      {/* Theme toggle in top-right corner */}
      <div className="absolute top-4 right-4 z-10">
        <ThemeToggle />
      </div>
      
      {/* Left side branding panel */}
      <div 
        className="hidden lg:flex lg:flex-col lg:w-1/2 relative overflow-hidden"
        style={{ 
          backgroundColor: 'var(--color-accent)',
          transition: 'all 0.5s ease-in-out'
        }}
      >
        <div className="absolute inset-0" style={{ 
          background: `radial-gradient(circle at 30% 40%, ${theme === 'dark' ? 'rgba(30, 64, 175, 0.8)' : 'rgba(0, 0, 0, 0.1)'} 0%, transparent 70%)`,
          zIndex: 1 
        }} />
        
        <div className="flex flex-col items-center justify-center h-full px-12 z-10 relative">
          <div 
            className={`transform transition-all duration-700 ${animationComplete ? 'translate-y-0 opacity-100 animate-fadeIn' : 'translate-y-8 opacity-0'}`}
            style={{ transitionDelay: '200ms' }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-white mb-6">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
          </div>
          
          <h1 
            className={`text-4xl font-bold text-white mb-4 transform transition-all duration-700 ${animationComplete ? 'translate-y-0 opacity-100 animate-slideDown' : 'translate-y-8 opacity-0'}`}
            style={{ transitionDelay: '300ms' }}
          >
            Inkooporders App
          </h1>
          
          <p 
            className={`text-white/80 text-lg text-center max-w-md transform transition-all duration-700 ${animationComplete ? 'translate-y-0 opacity-100 animate-fadeIn' : 'translate-y-8 opacity-0'}`}
            style={{ transitionDelay: '400ms' }}
          >
            Beheer uw inkooporders eenvoudig en efficiënt. Genereer professionele PDF documenten met slechts een paar klikken.
          </p>
          
          {/* Decorative elements */}
          <div className="absolute bottom-12 left-12 w-24 h-24 rounded-full bg-white/10"></div>
          <div className="absolute top-40 right-20 w-40 h-40 rounded-full bg-white/5"></div>
          <div className="absolute bottom-1/3 right-1/4 w-16 h-16 rounded-full bg-white/10"></div>
        </div>
      </div>
      
      {/* Right side login form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center">
        <div 
          className={`max-w-md w-full px-6 py-12 transform transition-all duration-700 ${animationComplete ? 'translate-y-0 opacity-100 animate-slideUp' : 'translate-y-8 opacity-0'}`} 
        >
          <div className="text-center mb-10">
            <h2 
              className="text-3xl font-bold mb-2" 
              style={{ color: 'var(--color-text-primary)' }}
            >
              Welkom terug
            </h2>
            <p 
              className="text-sm" 
              style={{ color: 'var(--color-text-secondary)' }}
            >
              Log in om toegang te krijgen tot uw dashboard
            </p>
          </div>
          
          {error && (
            <div 
              className="mb-6 rounded-lg px-4 py-3 flex items-center border-l-4 border-red-500 transition-all duration-300"
              style={{ 
                backgroundColor: theme === 'dark' ? 'rgba(220, 38, 38, 0.2)' : 'rgba(254, 226, 226, 1)',
                color: theme === 'dark' ? '#f87171' : '#dc2626'
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              <span>{error}</span>
            </div>
          )}
          
          {verificationSuccess && (
            <div className="mb-6 p-4 rounded-lg border border-green-500 bg-green-50 dark:bg-green-900/30 dark:border-green-700">
              <div className="flex items-center mb-2">
                <CheckCircle size={20} className="text-green-500 mr-2 flex-shrink-0" />
                <h3 className="font-medium text-green-700 dark:text-green-300">
                  E-mail succesvol geverifieerd
                </h3>
              </div>
              <p className="text-sm text-green-600 dark:text-green-400 mb-1">
                {verificationMessage}
              </p>
              {verificationEmail && (
                <p className="text-xs text-green-600 dark:text-green-400 font-medium">
                  Geverifieerd e-mail: {verificationEmail}
                </p>
              )}
            </div>
          )}
          
          {verificationRequired && !verificationSent && (
            <div className="mb-6 p-4 rounded-lg border border-blue-500 bg-blue-50 dark:bg-blue-900/30 dark:border-blue-700">
              <h3 className="font-medium text-blue-700 dark:text-blue-300 mb-2">
                E-mail verificatie vereist
              </h3>
              <p className="text-sm text-blue-600 dark:text-blue-400 mb-4">
                U moet uw e-mailadres verifiëren voordat u kunt inloggen. Check uw inbox of klik op onderstaande knop om een nieuwe verificatie e-mail te ontvangen.
              </p>
              <button
                type="button"
                onClick={handleResendVerification}
                disabled={resendingVerification}
                className="w-full py-2 px-4 rounded-lg font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
              >
                {resendingVerification ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Verificatie e-mail versturen...
                  </>
                ) : (
                  'Verstuur nieuwe verificatie e-mail'
                )}
              </button>
            </div>
          )}
          
          {verificationSent && (
            <div className="mb-6 p-4 rounded-lg border border-green-500 bg-green-50 dark:bg-green-900/30 dark:border-green-700">
              <h3 className="font-medium text-green-700 dark:text-green-300 mb-2">
                Verificatie e-mail verstuurd!
              </h3>
              <p className="text-sm text-green-600 dark:text-green-400">
                We hebben een verificatie e-mail verstuurd naar {verificationEmail}. Controleer uw inbox en klik op de link in de e-mail om uw account te verifiëren.
              </p>
            </div>
          )}
          
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="relative">
              <FormInput
                label="Email adres"
                name="email"
                type="email"
                placeholder="uw@email.nl"
                register={register}
                validation={loginValidation.email}
                errors={errors}
                required
                styles={{
                  input: `pl-10 ${verificationSuccess ? 'border-green-500 bg-green-50 dark:bg-green-900/20' : ''}`
                }}
                defaultValue={verificationEmail}
              />
              <div className="absolute top-[2.25rem] left-3 text-gray-400 pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              </div>
            </div>
            
            <div>
              <div className="flex items-center justify-between mb-2">
                <label 
                  htmlFor="password" 
                  className="block text-sm font-medium"
                  style={{ color: 'var(--color-text-secondary)' }}
                >
                  Wachtwoord
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <div className="text-xs">
                  <a href="#" style={{ color: 'var(--color-accent)' }} className="hover:underline">
                    Wachtwoord vergeten?
                  </a>
                </div>
              </div>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="••••••••"
                  className={`block w-full pl-10 pr-3 py-3 rounded-lg focus:outline-none focus:ring-2 transition-colors duration-200 ${errors.password ? 'border-red-500' : ''}`}
                  style={{ 
                    backgroundColor: 'var(--color-input-bg)',
                    borderColor: errors.password ? 'var(--color-error)' : 'var(--color-input-border)',
                    color: 'var(--color-text-primary)',
                    border: '1px solid'
                  }}
                  {...register('password', loginValidation.password)}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" style={{ color: 'var(--color-text-secondary)' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                  </svg>
                </div>
                {errors.password && (
                  <p className="mt-1 text-sm" style={{ color: 'var(--color-error)' }}>
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full py-3 px-4 rounded-lg font-medium text-white shadow-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 relative overflow-hidden group"
                style={{ 
                  backgroundColor: 'var(--color-accent)',
                  transform: isLoading ? 'scale(0.98)' : 'scale(1)'
                }}
              >
                <span className="absolute inset-0 w-full h-full transition duration-300 ease-out transform -translate-x-full bg-gradient-to-r from-blue-500 to-blue-400 group-hover:translate-x-0"></span>
                <span className="absolute inset-0 w-full h-full transition duration-300 ease-out transform translate-x-full bg-gradient-to-l from-blue-500 to-blue-400 group-hover:translate-x-0"></span>
                <span className="relative flex items-center justify-center">
                  {isLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Inloggen...
                    </>
                  ) : (
                    'Inloggen'
                  )}
                </span>
              </button>
            </div>

            <div className="mt-8 text-center">
              <p 
                className="text-sm"
                style={{ color: 'var(--color-text-secondary)' }}
              >
                Nog geen account?{' '}
                <Link to="/register" className="font-semibold hover:underline transition-colors duration-200" style={{ color: 'var(--color-accent)' }}>
                  Registreer nu
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;