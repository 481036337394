import axios from 'axios';
import { logError } from './errorService';

// Set API URL based on environment
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';
console.log('Using API URL:', API_URL);

// Create a base API instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true, // This enables sending cookies with requests
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN'
});

// Helper function to get CSRF token from cookies
const getCSRFToken = () => {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === 'XSRF-TOKEN') {
      return decodeURIComponent(value);
    }
  }
  console.warn('CSRF token not found in cookies');
  return null;
};

// For endpoints like registration and login that may not require CSRF token initially
const publicEndpoints = ['/auth/register', '/auth/login', '/auth/logout'];

// Add a request interceptor for any additional request configuration
api.interceptors.request.use(
  (config) => {
    // Add CSRF token to headers for non-GET requests (except for public endpoints)
    if (config.method !== 'get' && !publicEndpoints.includes(config.url)) {
      const csrfToken = getCSRFToken();
      if (csrfToken) {
        config.headers['X-XSRF-TOKEN'] = csrfToken;
        console.log('Added CSRF token to request');
      } else {
        console.warn('No CSRF token available for request to', config.url);
        // For non-public routes, still try to get a CSRF token first
        if (!config.url.startsWith('/auth/')) {
          console.log('Getting a CSRF token before proceeding...');
          // You could fetch a CSRF token here if needed
        }
      }
    }
    
    // Log request being made
    console.log(`Making ${config.method?.toUpperCase()} request to ${config.url}`);
    
    return config;
  },
  (error) => {
    logError(error, { message: 'Request interceptor error', context: 'api.interceptors.request' });
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => {
    console.log(`Response from ${response.config.url} - Status: ${response.status}`);
    return response;
  },
  (error) => {
    // Log error with our error service
    logError(error, { 
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      context: 'api.interceptors.response'
    });

    console.error('API Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data
    });

    // Handle CORS errors specifically
    if (error.message === 'Network Error') {
      console.error('CORS or network issue detected');
      return Promise.reject({ 
        message: 'Er kon geen verbinding worden gemaakt met de server. Controleer uw internetverbinding.',
        isCorsError: true 
      });
    }

    // Handle CSRF errors (403)
    if (error.response && error.response.status === 403 && 
        error.response.data && error.response.data.error === 'Invalid or missing CSRF token') {
      console.warn('CSRF validation failed, refreshing page to get new token');
      // Reload the page to get a fresh CSRF token
      window.location.reload();
      return Promise.reject(error);
    }

    // Handle rate limiting (429)
    if (error.response && error.response.status === 429) {
      console.warn('Rate limit exceeded');
      return Promise.reject({
        message: 'Te veel aanvragen. Probeer het over enkele minuten opnieuw.',
        isRateLimited: true
      });
    }

    // Handle unauthorized errors (401)
    if (error.response && error.response.status === 401) {
      console.log('Authentication failed, redirecting to login');
      // Redirect to login page when authentication fails
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

export default api;