// src/services/orderService.js
import api from './api';

// Get all orders
export const getOrders = async () => {
  try {
    const response = await api.get('/orders');
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || 'Failed to fetch orders');
  }
};

// Get a single order
export const getOrder = async (id) => {
  try {
    const response = await api.get(`/orders/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || `Failed to fetch order ${id}`);
  }
};

// Create a new order
export const createOrder = async (orderData) => {
  try {
    console.log('Sending order data:', JSON.stringify(orderData, null, 2));
    const response = await api.post('/orders', orderData);
    return response.data;
  } catch (error) {
    console.error('Order creation error:', error.response?.data || error.message);
    
    // Handle different types of errors
    if (error.response) {
      // The server responded with an error status
      const data = error.response.data;
      console.log('Validation errors:', JSON.stringify(data, null, 2));
      
      if (data.details) {
        // Server is using 'details' for validation errors
        const validationError = new Error(data.error || 'Validation failed');
        validationError.errors = data.details;
        throw validationError;
      } else if (data.errors) {
        // Server is using 'errors' for validation errors
        const validationError = new Error(data.message || 'Validation failed');
        validationError.errors = data.errors;
        throw validationError;
      } else if (data.message || data.error) {
        // Server error message
        throw new Error(data.message || data.error || 'An error occurred');
      }
    }
    
    // Generic error handling
    throw new Error(error.response?.data?.message || error.message || 'Failed to create order');
  }
};

// Update an order
export const updateOrder = async (id, orderData) => {
  try {
    const response = await api.put(`/orders/${id}`, orderData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || `Failed to update order ${id}`);
  }
};

// Delete an order
export const deleteOrder = async (id) => {
  try {
    const response = await api.delete(`/orders/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || `Failed to delete order ${id}`);
  }
};

// Update order status
export const updateOrderStatus = async (id, status) => {
  try {
    const response = await api.patch(`/orders/${id}/status`, { status });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message || `Failed to update status for order ${id}`);
  }
};